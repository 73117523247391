import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import Reach from gatsby
import { Router } from "@reach/router";

//Components
import Emprendimientos from '../templates/emprendimientos'
import Emprendimiento from '../templates/emprendimiento'
// import Main from '../components/Emprendimientos/main'

const Index = () => (

  <Router basepath="/emprendimientos">
    <Emprendimientos path="/" />
    <Emprendimiento path="/:id" />
  </Router>
)

export default Index
